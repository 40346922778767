<template>
  <!-- <div class="row">
    <div class="col-md-12">
      <label class="sub-title" for="baseNodeByRadius">BNs by Radius</label>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="address">Latitude</label>
        <input
          type="text"
          name="latitude"
          class="form-control"
          v-model="bn_latitude"
          :class="{
            'border-danger': !checkCoordinates(bn_latitude, 'latitude'),
          }"
          :disabled="bn_disabled"
          @change="findBNs"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="longitude">Longitude</label>
        <input
          type="text"
          name="location"
          class="form-control"
          v-model="bn_longitude"
          :class="{
            'border-danger': !checkCoordinates(bn_longitude, 'longitude'),
          }"
          :disabled="bn_disabled"
          @change="findBNs"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="height">Radius</label>
        <input
          type="text"
          name="height"
          class="form-control"
          v-model="bn_radius"
          :class="{ 'border-danger': !checkNumber(bn_radius) }"
          :disabled="bn_disabled"
          @change="findBNs"
        />
      </div>
    </div>
  </div> -->
</template>
<script>
export default {
  props: {
    operatorKey: String,
    operator: String,
  },
  data() {
    return {
      successful: false,
      bn_latitude: "",
      bn_longitude: "",
      bn_radius: "",
      bn_disabled: true,
      baseNodeErrorMsg: "",
      abortController: new AbortController(),
      baseNodeList: {
        showBNRadius: false,
        rectangleAPICall: false,
      },
    };
  },
  watch: {
    operator: {
      handler(newVal) {
        if (newVal) {
          this.findBNs();
        }
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    // ✅ Abort any ongoing request when component unmounts
    if (this.abortController) {
      this.abortController.abort();
    }
  },
  methods: {
    clearInputs() {
      this.bn_latitude = "";
      this.bn_longitude = "";
      this.bn_radius = "";
      this.axiosSource = "";
    },
    checkNumber(value) {
      return this.$utils.isValidNumber(value);
    },
    checkCoordinates(value, type = "") {
      return this.$utils.isValidCoordinates(value, type);
    },
    updateFields(data) {
      this.bn_latitude = data.latitude ? parseFloat(data.latitude) : "";
      this.bn_longitude = data.longitude ? parseFloat(data.longitude) : "";
      this.bn_radius = data.radius;
      this.baseNodeList.rectangleAPICall = true;
      this.findBNs();
    },
    findBNs() {
      if (
        this.bn_latitude !== "" &&
        this.bn_longitude !== "" &&
        this.bn_radius !== "" &&
        this.operator !== "" &&
        this.operatorKey !== ""
      ) {
        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/find/bns/by-radius";

        let jsonObject = {
          operator: this.operator,
          latitude: this.bn_latitude !== "" ? parseFloat(this.bn_latitude) : "",
          longitude:
            this.bn_longitude !== "" ? parseFloat(this.bn_longitude) : "",
          radius: this.bn_radius,
        };

        // Define the request data
        const requestData = {
          post_url: `${postUrl}?_t=${Date.now()}`, // Unique request to prevent caching
          api_key: this.operatorKey,
          json_data: jsonObject,
        };

        // Abort previous request if still running
        if (this.abortController) {
          this.abortController.abort();
        }

        // Create a new AbortController
        this.abortController = new AbortController();
        const { signal } = this.abortController;

        this.$axios
          .post("services", requestData, { signal })
          .then((response) => {
            if (response.data.detail) {
              this.baseNodeErrorMsg = response.data.detail;
            } else if (response.data.bn_list) {
              jsonObject.bn_list = response.data.bn_list;
              if (this.baseNodeList.rectangleAPICall) {
                jsonObject.rectangleAPICall = true;
              }
              this.baseNodeList = jsonObject;
              this.$emit("base-node-list", this.baseNodeList);
            }
          })
          .catch((error) => {
            if (this.$axios.isCancel(error)) {
              console.log("Request was canceled:", error.message);
              return;
            }
            console.error("Error:", error);
          });
      } else {
        this.$emit("get-operators");
      }
    },
  },
};
</script>
