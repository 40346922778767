<template>
  <div id="service_predictor">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-md-3 border-end p-0 overflow-y-auto"
          ref="sidebarContainer"
        >
          <div class="col-md-12">
            <div class="p-3 border-bottom">
              <div class="title">Service Availability Predictor</div>
              <div class="form-group">
                <label class="sub-title pb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  v-model="email"
                  :class="emailError ? 'border-danger' : ''"
                  @change="findOperators"
                  @keyup="isAuthenticationInputValid"
                />
                <div class="text-danger mt-2 mb-2" v-if="emailErrorMsg">
                  {{ emailErrorMsg }}
                </div>
              </div>
              <div class="form-group">
                <label class="sub-title pb-2">Operator API Key</label>
                <input
                  type="password"
                  name="operatorKey"
                  class="form-control"
                  v-model="operatorKey"
                  :class="operatorKeyError ? 'border-danger' : ''"
                  @change="findOperators"
                  @keyup="isAuthenticationInputValid"
                />
              </div>
              <div class="col-md-12 mt-2 mb-2">
                <div class="text-danger mb-2" v-if="keyResponse">
                  {{ keyResponse }}
                </div>
                <button
                  type="button"
                  class="btn btn-predictor"
                  :disabled="disableAuthButton"
                  @click="checkAPIKeyHealth"
                >
                  Authenticate
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-predictor ms-2"
                  @click="clearInputs"
                  v-if="keyResponse"
                >
                  <i class="fa fa-times"></i> Clear
                </button> -->
              </div>

              <div class="form-group">
                <label class="sub-title pb-2">Operator</label>
                <input
                  type="text"
                  name="operator"
                  class="form-control"
                  :value="operator"
                  :disabled="operatorDisabled"
                  :class="operatorError ? 'border-danger' : ''"
                  @change="updateOperator($event.target.value)"
                />
              </div>
              <div
                class="col-md-12 mt-2 mb-1 text-indigo"
                v-if="operatorLoading"
              >
                <i class="fa fa-spinner fa-spin me-1"></i> Fetching available
                operators. Please wait.
              </div>
              <div
                class="col-md-12 mt-2 mb-1 text-danger"
                v-if="
                  operatorError && operatorNames.length > 0 && !operatorLoading
                "
              >
                Operator entered is invalid.
                <a
                  href="#"
                  @click="showOperatorModal = true"
                  class="text-indigo"
                  >Click here</a
                >
                to select an operator.
              </div>
              <div
                class="col-md-12 mt-2 mb-1 text-danger"
                v-if="
                  operatorError &&
                  operatorNames.length == 0 &&
                  remoteAddress &&
                  !operatorLoading &&
                  !operatorDisabled
                "
              >
                There is no operator at this address. Please try again using
                another address.
              </div>
            </div>
          </div>
          <div class="col-md-12 px-3 mt-3">
            <div class="mt-2">
              <base-node
                ref="baseNodeRef"
                :operator-key="operatorKey"
                :operator="operator"
              />
              <bn-by-radius
                ref="baseNodeByRadiusRef"
                :operator-key="operatorKey"
                :operator="operator"
                @get-operators="findOperators"
                @base-node-list="getBaseNodeList"
              />
            </div>
            <div class="mt-2">
              <remote-node
                ref="remoteNodeRef"
                @get-operators="findOperators"
                @clear-rn-marker="handleClearRNMarker"
              />
            </div>
          </div>
          <div class="col-md-12 p-3 mb-3">
            <button
              type="button"
              class="btn btn-predictor me-2 mb-1"
              @click="calculateLost"
            >
              Calculate Path Loss & Speed
            </button>
            <button
              type="button"
              class="btn btn-predictor me-2 mb-1"
              v-if="successful && !showInfoDiv"
              @click="toggleModal"
            >
              Show Path Profile
            </button>
            <button
              type="button"
              class="btn btn-predictor mb-1"
              v-if="baseNodeList.bn_list?.length"
              @click="toggleBNRadius"
            >
              {{ showBNRadiusDiv ? "Hide Sectors" : "Show Sectors" }}
            </button>
          </div>
        </div>
        <div class="col-md-9 p-0">
          <div class="container-fluid">
            <div class="row no-gutters">
              <div class="col-md-12 p-0 position-relative">
                <google-map
                  :load-map-radius="loadMapRadius"
                  :base-node-list="baseNodeList"
                  :remote-address="remoteAddress"
                  :remote-lat-lng="remoteLatLng"
                  :sector-results="sectorResults"
                  :multi-sector="multiSector"
                  :clear-remote-node="clearRNMarker"
                  :load-base-nodes="loadingBaseNodes"
                  @reset-all="clearInputs"
                  @update-lat-lng="handleUpdateLatLng"
                  @update-bn-radius="handleUpdateBaseNodeRadius"
                  @update-base-node="handleUpdateBaseNode"
                  @update-results-modal="handleUpdateResultsModal"
                />
                <!-- Overlay Modal -->
                <div class="overlay" v-if="successful && showInfoDiv">
                  <div class="overlay-content">
                    <div class="card">
                      <div class="card-body set-max-height">
                        <div class="close" @click="toggleModal">&times;</div>
                        <div class="row">
                          <div class="col-md-12" v-if="apiResponse">
                            <div class="text-danger text-center">
                              API Response: {{ apiResponse }}<br />Please try
                              again using another address.
                            </div>
                          </div>
                          <div
                            class="modal-flex-container col-md-12"
                            v-if="profileSvg"
                          >
                            <div class="info-column">
                              <!--<div class="info-text" v-if="linkCloseConfidence">
                                Link Close Confidence:
                                {{ (linkCloseConfidence * 100).toFixed(0) }}%
                              </div>
                              <div class="info-text" v-if="pathLossData">
                                Predicted Path Loss:
                                {{ pathLossData.average }}
                              </div> -->
                              <template v-for="remoteInfo in remoteNodeDetails">
                                <div
                                  class="info-box"
                                  :class="{
                                    'acive-info':
                                      remoteInfo === activeRemoteInfo,
                                  }"
                                  @click="appendResultsOnModal(remoteInfo)"
                                >
                                  Height:
                                  <span class="fw-bold">{{
                                    showHeightText(remoteInfo)
                                  }}</span
                                  ><br />Link Close Confidence:
                                  <span class="fw-bold"
                                    >{{
                                      (
                                        remoteInfo?.aiml_output
                                          ?.link_close_confidence * 100
                                      ).toFixed(0)
                                    }}%</span
                                  ><br />
                                  Predicted Path Loss:
                                  <span
                                    class="fw-bold"
                                    v-html="getPathLoss(remoteInfo)"
                                  ></span
                                  ><br />
                                  <span v-if="devOnly === 'true'">
                                    Link Speed:
                                    <span class="fw-bold">{{
                                      calculateSpeed(remoteInfo)
                                    }}</span></span
                                  >
                                  <div v-if="remoteInfo === activeRemoteInfo">
                                    RN Mount:
                                    <div class="ps-3">
                                      Height AGL:
                                      <span
                                        class="fw-bold"
                                        v-html="showRNMountHeight(remoteInfo)"
                                      ></span
                                      ><br />
                                      Azimuth:
                                      <span
                                        class="fw-bold"
                                        v-html="getAzimuth(remoteInfo)"
                                      ></span
                                      ><br />
                                      Tilt:
                                      <span
                                        class="fw-bold"
                                        v-html="getTilt(remoteInfo)"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                            <div class="graph-column img-enlarge">
                              <div class="diagram-title">Link Diagram</div>
                              <img
                                :src="profileSvg"
                                class="svg-container"
                                id="profile-svg-container"
                                @click="enlargeImage('line')"
                              />
                              <button
                                class="enlarge-button"
                                @click="enlargeImage('line')"
                              >
                                <i class="fa fa-expand"></i> Enlarge Image
                              </button>
                            </div>
                          </div>
                          <!-- <div class="col-md-12 graph-bg">
                            <div class="row">
                              <div
                                class="col-md-6 img-enlarge"
                                v-if="pathLossData"
                              >
                                <img
                                  v-if="pathLossSvg"
                                  :src="pathLossSvg"
                                  class="svg-container"
                                  id="path-loss-svg-container"
                                />
                                <button
                                  class="enlarge-button"
                                  @click="enlargeImage('path-loss')"
                                >
                                  <i class="fa fa-expand"></i> Enlarge Image
                                </button>
                              </div>
                              <div
                                class="col-md-6 img-enlarge"
                                v-if="linkSpeedData"
                              >
                                <img
                                  v-if="linkSpeedSvg"
                                  :src="linkSpeedSvg"
                                  class="svg-container"
                                  id="link-speed-svg-container"
                                />
                                <button
                                  class="enlarge-button"
                                  @click="enlargeImage('link-speed')"
                                >
                                  <i class="fa fa-expand"></i> Enlarge Image
                                </button>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="dialog"
        id="svg-popup"
        v-if="dialogVisible"
        @click.self="closeDialog"
      >
        <div
          class="dialog-content"
          :class="svgType === 'line' ? 'diagram-width' : 'graph-width'"
          @click.stop
        >
          <button @click="closeDialog" class="close-icon">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <img
                :src="svgContent"
                class="dialog-svg-container"
                id="svg-container"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    :class="showOperatorModal ? 'd-block' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Operator List</h5>
          <button
            type="button"
            class="btn-close"
            @click="showOperatorModal = false"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="operatorNames.length > 0">
            <p>Select an operator from the list below:</p>
            <ul class="list-group">
              <li
                v-for="(operator, index) in operatorNames"
                :key="index"
                class="list-group-item fw-bold"
              >
                {{ operator }}
                <div class="float-end">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="selectOperator(operator)"
                  >
                    Select Operator
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <p>No operators found at this address.</p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="showOperatorModal = false"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseNode from "./BaseNode.vue";
import RemoteNode from "./RemoteNode.vue";
import BNByRadius from "./BNByRadius.vue";
import GoogleMap from "./GoogleMap.vue";
export default {
  components: {
    "base-node": BaseNode,
    "remote-node": RemoteNode,
    "bn-by-radius": BNByRadius,
    "google-map": GoogleMap,
  },
  data() {
    return {
      successful: false,
      operatorKey: "",
      email: "",
      operator: "",
      keyResponse: "",
      remoteAddress: "",
      remoteLatLng: {},
      showOperatorModal: false,
      disableAuthButton: true,
      emailError: false,
      emailErrorMsg: "",
      operatorError: false,
      operatorLoading: false,
      operatorKeyCheck: false,
      operatorKeyError: false,
      operatorDisabled: true,
      clearRNMarker: false,
      activeTab: "base-remote-node",
      svgContent: "",
      profileSvg: "",
      pathLossData: "",
      linkSpeedData: "",
      pathLossSvg: "",
      linkSpeedSvg: "",
      linkCloseConfidence: 0,
      multiSector: false,
      showInfoDiv: false,
      showBNRadiusDiv: false,
      dialogVisible: false,
      loadMapRadius: false,
      loadingBaseNodes: false,
      updateBaseNode: false,
      abortController: new AbortController(),
      svgType: "",
      apiResponse: "",
      activeRemoteInfo: null,
      devOnly: process.env.VUE_APP_DEV_ONLY_FEATURE,
      activeBaseInfo: null,
      operatorNames: [],
      baseNodeList: [],
      sectorResults: [],
      remoteNodeDetails: [],
    };
  },
  mounted() {
    const rowElement = this.$refs.sidebarContainer;
    if (rowElement && window.innerWidth > 767) {
      rowElement.style.height = window.innerHeight - 40 + "px";
    }
    this.toogleActiveTab(this.activeTab);
    window.addEventListener("resize", this.toggleModal);
    if (localStorage.getItem("operator_key")) {
      this.operatorKey = localStorage.getItem("operator_key");
    }
    if (localStorage.getItem("email")) {
      this.email = localStorage.getItem("email");
    }
    if (this.operatorKey && this.email) {
      this.disableAuthButton = false;
      this.checkAPIKeyHealth();
    }
  },
  watch: {
    profileSvg() {
      this.makeSVGResponsive("profile-svg-container");
    },
    pathLossSvg() {
      this.makeSVGResponsive("path-loss-svg-container");
    },
    linkSpeedSvg() {
      this.makeSVGResponsive("link-speed-svg-container");
    },
  },
  methods: {
    toogleActiveTab(tab) {
      this.activeTab = tab;
      let remoteRef = this.$refs.remoteNodeRef;
      remoteRef.findByAddress = true;
      remoteRef.remoteAddress = true;
    },
    clearInputs(data) {
      this.emailErrorMsg = "";
      this.keyResponse = "";
      this.remoteAddress = "";
      this.svgContent = "";
      this.profileSvg = "";
      this.pathLossData = "";
      this.linkSpeedData = "";
      this.pathLossSvg = "";
      this.linkSpeedSvg = "";
      this.svgType = "";
      this.apiResponse = "";
      this.linkCloseConfidence = 0;
      this.operatorNames = [];
      this.sectorResults = [];

      this.successful = false;
      this.showOperatorModal = false;
      this.showInfoDiv = false;
      this.showBNRadiusDiv = true;
      this.dialogVisible = false;
      this.clearRNMarker = false;
      this.$refs.remoteNodeRef.clearInputs();
      if (data.remoteOnly) {
        this.$refs.baseNodeRef.clearInputs();
        this.$refs.baseNodeByRadiusRef.clearInputs();
      }
    },
    enlargeImage(diagram) {
      this.dialogVisible = true;
      if (diagram == "line") {
        this.svgContent = this.profileSvg;
        this.svgType = "line";
      }
      if (diagram == "path-loss") {
        this.svgContent = this.pathLossSvg;
        this.svgType = "graph";
      }
      if (diagram == "link-speed") {
        this.svgContent = this.linkSpeedSvg;
        this.svgType = "graph";
      }
      // Call makeSVGResponsive after SVG content is updated
      this.$nextTick(() => {
        this.makeDialogSVGResponsive();
      });
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    toggleModal() {
      this.showInfoDiv = !this.showInfoDiv;
      if (this.showInfoDiv) {
        // Call makeSVGResponsive after SVG content is updated
        this.$nextTick(() => {
          this.makeSVGResponsive("profile-svg-container");
          this.makeSVGResponsive("path-loss-svg-container");
          //this.makeSVGResponsive("link-speed-svg-container");
        });
      }
    },
    makeSVGResponsive(svg_id) {
      const svgContainer = document.getElementById(svg_id);
      if (svgContainer) {
        const svg = svgContainer.querySelector("svg");
        if (svg) {
          svg.removeAttribute("width");
          svg.removeAttribute("height");

          // Set viewBox attribute based on the SVG content's bounding box
          const bbox = svg.getBBox();
          svg.setAttribute(
            "viewBox",
            `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
          );

          let diagram = "";
          if (svg_id == "path-loss-svg-container") {
            diagram = "path-loss";
          } else if (svg_id == "link-speed-svg-container") {
            diagram = "link-speed";
          } else {
            diagram = "line";
          }

          // Add click event listener to the svg element
          svg.addEventListener("click", () => {
            this.enlargeImage(diagram);
          });
        }
      }
      const rowElement = this.$refs.sidebarContainer;
      if (rowElement && window.innerWidth > 767) {
        rowElement.style.height = window.innerHeight - 40 + "px";
      }
    },
    makeDialogSVGResponsive() {
      const svgId = "svg-container";
      const svgContainer = document
        .getElementById("svg-popup")
        .querySelector(`#${svgId}`);
      if (svgContainer) {
        const svg = svgContainer.querySelector("svg");
        if (svg) {
          svg.removeAttribute("width");
          svg.removeAttribute("height");

          // Set viewBox attribute based on the SVG content's bounding box
          const bbox = svg.getBBox();
          svg.setAttribute(
            "viewBox",
            `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
          );
        }
      }
    },
    selectOperator(val) {
      this.operator = val;
      this.operatorError = false;
      this.showOperatorModal = false;
      this.loadingBaseNodes = true;
      this.loadMapRadius = true;
    },
    checkAPIKeyHealth() {
      this.emailError = false;
      this.operatorKeyError = false;
      this.loadMapRadius = false;
      this.operatorDisabled = true;
      this.disableAuthButton = false;
      this.operatorError = false;
      this.operator = "";
      this.keyResponse = "";
      if (this.operatorKey != "" && this.isEmailValid()) {
        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });

        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/health/api-key";

        // Define the request data
        const requestData = {
          post_url: postUrl,
          api_key: this.operatorKey,
        };

        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              loader.hide();
              this.operatorKeyCheck = true;
              this.loadMapRadius = true;
              if (response.data.detail) {
                this.keyResponse = response.data.detail;
                this.operatorKeyError = true;
                if (localStorage.getItem("operator_key")) {
                  localStorage.removeItem("operator_key");
                }
                if (localStorage.getItem("email")) {
                  localStorage.removeItem("email");
                }
              } else {
                if (!response.data.operator) {
                  this.keyResponse = "Invalid API Key";
                } else {
                  if (response.data.operator != "Tarana") {
                    this.operator = response.data.operator;
                  } else {
                    this.operatorDisabled = false;
                  }
                  localStorage.setItem("operator_key", this.operatorKey);
                  localStorage.setItem("email", this.email);
                  alert("Successfully Authenticated");
                }
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.operatorKeyError = this.operatorKey != "" ? false : true;
        this.emailError = this.isEmailValid() ? false : true;
        this.disableAuthButton =
          !this.operatorKeyError && !this.emailError ? false : true;
      }
    },
    handleUpdateLatLng(data) {
      this.$refs.remoteNodeRef.updateFields(data);
      if (!data.clicked && data.address) {
        this.findOperators();
      }
    },
    handleUpdateBaseNodeRadius(data) {
      this.loadingBaseNodes = true;
      this.loadMapRadius = false;
      let baseRef = this.$refs.baseNodeByRadiusRef;
      baseRef.updateFields(data);
      this.operatorError = this.operator != "" ? false : true;
      let remoteRef = this.$refs.remoteNodeRef;
      remoteRef.addressUpdated = true;
      this.remoteAddress = remoteRef.rn_address;
    },
    handleUpdateBaseNode(data) {
      let baseRef = this.$refs.baseNodeRef;
      if (data) this.activeBaseInfo = data;
      if (baseRef.baseNodeOption != "all" || this.updateBaseNode) {
        baseRef.updateFields(data);
        this.updateBaseNode = false;
      }
    },
    isAuthenticationInputValid() {
      this.disableAuthButton =
        this.operatorKey != "" && this.isEmailValid() ? false : true;
    },
    isEmailValid() {
      this.emailErrorMsg = "";
      if (this.email != "") {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const checkEmail = emailPattern.test(this.email);
        if (!checkEmail) {
          this.emailErrorMsg = "Invalid Email";
        }
        return checkEmail;
      }
      return false;
    },
    handleClearRNMarker() {
      this.clearRNMarker = true;
    },
    findOperators() {
      let remoteRef = this.$refs.remoteNodeRef;
      remoteRef.addressError = false;
      if (remoteRef.addressUpdated) {
        this.remoteAddress = remoteRef.rn_address;
      }
      this.remoteLatLng = {};
      if (remoteRef.latlngUpdated) {
        this.remoteLatLng = remoteRef.rn_location ?? {};
      }
      this.loadingBaseNodes = false;
      if (this.remoteAddress && this.operatorKey && !this.operatorDisabled) {
        this.operatorLoading = true;
        this.operatorNames = [];
        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/find/operators";

        // Define the request data
        const requestData = {
          post_url: `${
            postUrl + "/" + encodeURIComponent(this.remoteAddress)
          }?_t=${Date.now()}`, // Unique request to prevent caching
          api_key: this.operatorKey,
        };

        // Abort previous request if still running
        if (this.abortController) {
          this.abortController.abort();
        }

        // Create a new AbortController
        this.abortController = new AbortController();
        const { signal } = this.abortController;

        this.$axios
          .post("services", requestData, { signal })
          .then((response) => {
            this.operatorLoading = false;
            if (response.data.detail) {
              this.addressErrorMsg = response.data.detail;
            } else if (response.data.operator_list) {
              this.operatorNames = response.data.operator_list.map(
                (operator) => operator.name
              );
              this.checkOperatorExist();
            }
          })
          .catch((error) => {
            if (this.$axios.isCancel(error)) {
              console.log("Request was canceled:", error.message);
              return;
            }
            console.error("Error:", error);
          });
      } else {
        remoteRef.addressError = !this.remoteAddress ? true : false;
      }
    },
    getBaseNodeList(data = "") {
      this.loadingBaseNodes = false;
      this.loadMapRadius = false;
      this.showBNRadiusDiv = true;
      if (data) {
        this.baseNodeList = data;
        this.baseNodeList.showBNRadius =
          this.baseNodeList.bn_list.length > 0 ? true : false;
      }
    },
    toggleBNRadius() {
      this.showBNRadiusDiv = !this.showBNRadiusDiv;
      this.baseNodeList.showBNRadius = this.showBNRadiusDiv;
    },
    isFormValid() {
      // Perform validation on BaseNode and RemoteNode
      const baseNodeValid = this.$refs.baseNodeRef.isFormValid();
      const remoteNodeValid = this.$refs.remoteNodeRef.isFormValid();
      return (
        baseNodeValid &&
        remoteNodeValid &&
        this.operatorKey.trim() &&
        this.email.trim() &&
        this.operator.trim() &&
        !this.operatorError &&
        !this.operatorLoading
      );
    },
    updateOperator(value) {
      this.operator = value ?? "";
      this.checkOperatorExist();
    },
    checkOperatorExist() {
      this.operatorError = false;
      if (this.operatorNames.length > 0) {
        if (!this.operatorNames.includes(this.operator)) {
          this.operatorError = true;
        }
      } else {
        this.operatorError = true;
      }
    },
    calculateLost() {
      let remoteRef = this.$refs.remoteNodeRef;
      let baseRef = this.$refs.baseNodeRef;

      if (this.isFormValid()) {
        this.successful = false;
        this.showInfoDiv = false;
        this.multiSector = false;
        this.apiResponse = "";
        let jsonArray = [];
        this.sectorResults = [];

        let bnObject = baseRef.getJsonData();
        if (!baseRef.disabled && baseRef.baseNodeOption != "all") {
          jsonArray["bn"] = bnObject;
        }

        let rnObject = remoteRef.getJsonData();
        jsonArray["rn_list"] = [rnObject];

        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });

        let postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/predict/sector";

        if (baseRef.baseNodeOption == "all") {
          postUrl =
            "https://tcc-network-planning.uw.r.appspot.com/v0/predict/multi-sector";
        }

        // Define the request data
        const jsonObject = { ...jsonArray };
        if (this.operator != "") {
          jsonObject.operator = this.operator;
        }
        if (this.email != "") {
          jsonObject.email = this.email;
        }
        const requestData = {
          post_url: postUrl,
          json_data: jsonObject,
          api_key: this.operatorKey,
        };

        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              this.successful = true;
              this.showInfoDiv = true;
              loader.hide();

              if (response.data.detail) {
                this.apiResponse = response.data.detail;
              } else {
                if (response.data.sector_list) {
                  this.multiSector = true;
                }
                this.sectorResults = response.data;
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.operatorKeyError = this.operatorKey != "" ? false : true;
        this.checkOperatorExist();
      }
    },
    showHeightText(remoteData) {
      let heightType = remoteData?.mount?.location ?? "";
      let heightMountText = "";
      if (heightType) {
        let rnMountHeight =
          heightType.toLowerCase() == "above building"
            ? remoteData?.rn?.heights?.above_roof
            : remoteData?.rn?.heights?.above_open;
        heightMountText += rnMountHeight + " m ";
      }

      heightType = heightType.replace(/\b\w/g, (char) => char.toUpperCase());

      return heightMountText + heightType;
    },
    showRNMountHeight(remoteData) {
      const height = remoteData?.mount?.height_agl ?? 0;
      let heightText = height + " m";
      return heightText;
    },
    getTilt(remoteInfo) {
      if (remoteInfo?.rn_tilt !== undefined) {
        return parseFloat(remoteInfo?.rn_tilt).toFixed(1) + "&deg;";
      }
      return "";
    },
    getAzimuth(remoteInfo) {
      if (remoteInfo?.bn_rn_angle !== undefined) {
        return ((parseFloat(remoteInfo.bn_rn_angle) + 180) % 360) + "&deg;";
      }
      return "";
    },
    getPathLoss(remoteInfo) {
      if (remoteInfo?.aiml_output?.path_loss?.average !== undefined) {
        return (
          Math.round(parseFloat(remoteInfo?.aiml_output?.path_loss?.average)) +
          " dB"
        );
      }
      return "";
    },
    sortRemoteData(remoteNodes) {
      return remoteNodes
        .slice() // Create a copy to avoid mutating the original array
        .sort((a, b) => {
          const heightA = a?.mount?.height_agl ?? 0;
          const heightB = b?.mount?.height_agl ?? 0;
          return heightB - heightA; // Descending order (highest height first)
        });
    },
    handleUpdateResultsModal(data) {
      if (!data) {
        this.apiResponse =
          "No results are displayed as all remote nodes have a link close confidence below 0.25.";
      } else {
        if (data.remote_list) {
          this.remoteNodeDetails = this.sortRemoteData(data.remote_list);
        }
        if (data.remote_data) {
          this.apiResponse = "";
          this.updateBaseNode = true;
          this.appendResultsOnModal(data.remote_data);
        }
        if (data.line_click) {
          this.showInfoDiv = true;
        }
      }
    },
    appendResultsOnModal(remoteData) {
      this.activeRemoteInfo = remoteData;
      if (remoteData.aiml_output.path_loss) {
        this.pathLossData = remoteData.aiml_output.path_loss;
        this.pathLossSvg = this.pathLossData.cdf_url;
        // Call makeSVGResponsive after SVG content is updated
        this.$nextTick(() => {
          this.makeSVGResponsive("path-loss-svg-container");
        });
      }
      if (remoteData.aiml_output.link_speed) {
        this.linkSpeedData = remoteData.aiml_output.link_speed;
        this.linkSpeedSvg = this.linkSpeedData.cdf_url;
        // Call makeSVGResponsive after SVG content is updated
        this.$nextTick(() => {
          this.makeSVGResponsive("link-speed-svg-container");
        });
      }
      if (remoteData.aiml_output.link_close_confidence) {
        this.linkCloseConfidence = remoteData.aiml_output.link_close_confidence;
      }
      if (remoteData.profile_url) {
        this.profileSvg = remoteData.profile_url;
        // Call makeSVGResponsive after SVG content is updated
        this.$nextTick(() => {
          this.makeSVGResponsive("profile-svg-container");
        });
      }
    },
    calculateSpeed(remoteInfo) {
      let pathLoss = remoteInfo?.aiml_output?.path_loss?.average;
      //let linkSpeed = remoteInfo?.aiml_output?.link_speed?.average;
      let calculatedSpeed = 0;
      if (this.activeBaseInfo) {
        let bnCarriers = this.activeBaseInfo.carrier[0].frequency;
        calculatedSpeed = this.mapl(pathLoss, bnCarriers);
      }
      let speed = parseFloat(calculatedSpeed);
      return Math.round(speed) + " Mbps";
    },
    mapl(pathLoss, carrier) {
      if (carrier < 5000) {
        return Math.min(
          Math.max((640.0 * (160.0 - pathLoss)) / (160.0 - 133.0), 0.0),
          640.0
        );
      } else {
        return Math.min(
          Math.max((640.0 * (148.0 - pathLoss)) / (148.0 - 123.0), 0.0),
          640.0
        );
      }
    },
  },
  beforeUnmount() {
    // ✅ Abort any ongoing request when component unmounts
    if (this.abortController) {
      this.abortController.abort();
    }
  },
};
</script>
